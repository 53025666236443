<component-top-navigation></component-top-navigation>
<component-loader [active]="showLoader"></component-loader>
<!-- <component-modal-info></component-modal-info> -->
<component-modal [mode]="'create'" [user]="user" [clients]="clients" (itemEdited)="onItemEdit($event)" (itemDeleted)="onItemDelete($event)"> </component-modal>
<component-notification [notification]="notification"></component-notification>

<div class="screenContainer">
  <component-filters
    [categories]="categories"
    [filters]="filters"
    [mode]="'create'"
    [searchText]="searchText"
    [selectedClient]="selectedClient"
    [selections]="selections"
    [tabFacets]="tabFacets"
    (onClickFilters)="onClickFilters($event)"
    (onClickSearch)="onClickSearch($event)"
    (onSelectClient)="onSelectClient($event)"
    (closeCanvas)="onClickCloseLook()">
  </component-filters>
  <div class="mainScreen">
    <section class="main">
      <component-canvas-screen
        [selectedClient]="selectedClient"
        [featureLook]="feature"
        [pinnedLook]="isPinnedLook"
        [pinnedItemUid]="pinnedItemUid"
        [itemList]="canvasLook.items"
        (onClickEdit)="onClickEditLook($event)">
      </component-canvas-screen>
      <component-look-controls
        [flagReview]="flag"
        [publishSite]="publish"
        [featureLook]="feature"
        [pinnedLook]="isPinnedLook"
        [itemList]="canvasLook.items"
        [showButtons]="true"
        (onClickSave)="onClickSaveLook()"
        (onClickClose)="onClickCloseLook()"
        (onChangeFlag)="onChangeFlag()"
        (onChangePublish)="onChangePublish()"
        (onChangeFeature)="onChangeFeature()">
      </component-look-controls>
    </section>
    <section [class.sticky-margin]="stickyCanvas">
      <component-item-container [items]="items" [disableScroll]="disableScroll" (onLoadMoreItems)="loadMoreItems()"> </component-item-container>
    </section>
  </div>
</div>
