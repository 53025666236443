import gql from 'graphql-tag';

export interface Client {
  level: string;
  name: string;
  uid: string;
  offlineImageProcessing: boolean;
  showPreReleaseFilter: boolean;
  pinnedLooksEnabled: boolean;
  editItemStockEnabled: boolean;
  visuallySimilarEnabled: boolean;
  runwayCtlEnabled: boolean;
  runwayAppId: string;
  displayItemUni: boolean;
  showLooksWithMissingImages: boolean;
}

export const ClientsQuery = gql`
  query Clients {
    clients {
      name
      uid
      offlineImageProcessing
      showPreReleaseFilter
      pinnedLooksEnabled
      editItemStockEnabled
      visuallySimilarEnabled
      runwayCtlEnabled
      runwayAppId
      displayItemUni
      showLooksWithMissingImages
      locales
      defaultLocale
    }
  }
`;
