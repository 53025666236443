<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<nav class="leftNavigation" *ngIf="selectedClient">
  <!-- CREATE TAB TEXT SEARCH -->
  <ng-container *ngIf="mode !== 'snapshot'">
    <h2>SEARCH</h2>
    <div class="leftNavigation__search" *ngIf="mode === 'create'">
      <input type="text" class="leftNavigation__searchBox" placeholder="{{ 'TITLE, PID, or UID' }}" [(ngModel)]="searchText" (keyup.enter)="search()" />
      <button class="leftNavigation__searchButton" (click)="search()">
        <span class="leftNavigation__searchButtonIcon glyphicons glyphicons-search"></span>
      </button>
    </div>
    <!-- MANAGE TAB LOOK UID SEARCH -->
    <div class="leftNavigation__search" *ngIf="mode === 'manage'">
      <input
        class="leftNavigation__searchBox"
        type="text"
        placeholder="{{ 'LOOK UID' }}"
        [(ngModel)]="searchText"
        (keyup.enter)="search($event.target.value)" />
      <button class="leftNavigation__searchButton" (click)="search()">
        <span class="leftNavigation__searchButtonIcon glyphicons glyphicons-search"></span>
      </button>
    </div>

    <h2>FILTER</h2>
    <div class="leftNavigation__filters">
      <div class="leftNavigation__filtersContainer">
        <div class="leftNavigation__clear">
          <a class="leftNavigation__clearLink" (click)="clearSelections()">CLEAR ALL</a>
        </div>
        <div *ngIf="mode === 'create'">
          <!-- Count -->
          <div class="leftNavigation__filtersOption">
            <label class="leftNavigation__filtersOption__label">
              <span class="leftNavigation__filtersOption__labelText">Count</span>
              <span class="leftNavigation__filtersOption__count">{{ itemCount }}</span>
            </label>
          </div>
          <!-- Status & Image Processing -->
          <ng-container *ngFor="let config of tabFacets">
            <!-- Only show Image Processing for Gap and Athleta -->
            <div
              class="leftNavigation__filtersOption"
              *ngIf="config.name === 'status' || (config.name === 'image processing' && selectedClient.offlineImageProcessing)">
              <label class="leftNavigation__filtersOption__label" id="status" (click)="showFilter(config)">
                <span class="leftNavigation__filtersOption__labelText">{{ config.name }}</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': config.visible }"></span>
              </label>
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': config.visible }"
                [items]="config.items"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
          </ng-container>

          <ng-container *ngFor="let filter of filters; let i = index">
            <!-- Brand filter contains searchable input field -->
            <div class="leftNavigation__filtersOption" *ngIf="filter.name === 'brand' && filter.items.length > 0">
              <label class="leftNavigation__filtersOption__label" id="brands" (click)="showFilter(filter)">
                <span class="leftNavigation__filtersOption__labelText">Brands</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': filter.visible }"></span>
              </label>
              <input
                class="leftNavigation__filtersSubList__filterInput"
                [ngClass]="{ 'leftNavigation__filtersSubList__filterInput--active': filter.visible }"
                [(ngModel)]="filterText"
                placeholder="FILTER BRANDS" />
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': filter.visible }"
                [items]="filter.items"
                [facet]="filter.name"
                [filterText]="filterText"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
            <!-- End Use filter name contains an underscore that needs to be removed for display purposes -->
            <div class="leftNavigation__filtersOption" *ngIf="filter.name === 'end_use' && filter.items.length > 0">
              <label class="leftNavigation__filtersOption__label" id="end_use" (click)="showFilter(filter)">
                <span class="leftNavigation__filtersOption__labelText">End Use</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': filter.visible }"></span>
              </label>
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': filter.visible }"
                [items]="filter.items"
                [facet]="filter.name"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
            <!-- All other filters -->
            <div class="leftNavigation__filtersOption" *ngIf="filter.name !== 'brand' && filter.name !== 'end_use' && filter.items.length > 0">
              <label class="leftNavigation__filtersOption__label" id="filterOrder[i]" (click)="showFilter(filter)">
                <span class="leftNavigation__filtersOption__labelText">{{ filter.name }}</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': filter.visible }"></span>
              </label>
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': filter.visible }"
                [items]="filter.items"
                [facet]="filter.name"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
          </ng-container>
          <!-- Categories -->
          <ng-container>
            <div class="leftNavigation__filtersOption" *ngIf="categories && categories.length > 0">
              <label class="leftNavigation__filtersOption__label" id="categories" (click)="showFilter(categories)">
                <span class="leftNavigation__filtersOption__labelText">Categories</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': categories.visible }"></span>
              </label>
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': categories.visible }"
                [items]="categories"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
          </ng-container>
        </div>
        <!-- MANAGE -->
        <div *ngIf="mode === 'manage'">
          <!-- Count -->
          <div class="leftNavigation__filtersOption">
            <label class="leftNavigation__filtersOption__label">
              <span class="leftNavigation__filtersOption__labelText">Count</span>
              <span class="leftNavigation__filtersOption__count">{{ lookCount }}</span>
            </label>
          </div>

          <!-- Filters (Brand/Team/etc.) -->
          <ng-container *ngFor="let filter of filters; let i = index">
            <!-- Brand filter contains searchable input field -->
            <div class="leftNavigation__filtersOption" *ngIf="filter.name === 'brand' && filter.items.length > 0">
              <label class="leftNavigation__filtersOption__label" id="brands" (click)="showFilter(filter)">
                <span class="leftNavigation__filtersOption__labelText">Brands</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': filter.visible }"></span>
              </label>
              <input
                class="leftNavigation__filtersSubList__filterInput"
                [ngClass]="{ 'leftNavigation__filtersSubList__filterInput--active': filter.visible }"
                [(ngModel)]="filterText"
                placeholder="FILTER BRANDS" />
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': filter.visible }"
                [items]="filter.items"
                [facet]="filter.name"
                [filterText]="filterText"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
            <!-- End Use filter name contains an underscore that needs to be removed for display purposes -->
            <div class="leftNavigation__filtersOption" *ngIf="filter.name === 'end_use' && filter.items.length > 0">
              <label class="leftNavigation__filtersOption__label" id="end_use" (click)="showFilter(filter)">
                <span class="leftNavigation__filtersOption__labelText">End Use</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': filter.visible }"></span>
              </label>
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': filter.visible }"
                [items]="filter.items"
                [facet]="filter.name"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
            <!-- All other filters -->
            <div class="leftNavigation__filtersOption" *ngIf="filter.name !== 'brand' && filter.name !== 'end_use' && filter.items.length > 0">
              <label class="leftNavigation__filtersOption__label" id="filterOrder[i]" (click)="showFilter(filter)">
                <span class="leftNavigation__filtersOption__labelText">{{ filter.name }}</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': filter.visible }"></span>
              </label>
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': filter.visible }"
                [items]="filter.items"
                [facet]="filter.name"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
          </ng-container>

          <!-- Look Users -->
          <ng-container>
            <div class="leftNavigation__filtersOption" *ngFor="let user of lookUsers">
              <div *ngIf="user.items.length > 0">
                <label class="leftNavigation__filtersOption__label" (click)="showFilter(user)">
                  <span class="leftNavigation__filtersOption__labelText">{{ user.name }}s</span>
                  <span
                    class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                    [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': user.visible }"></span>
                </label>
                <component-sub-filters
                  class="leftNavigation__filtersSubList"
                  [ngClass]="{ 'leftNavigation__filtersSubList--active': user.visible }"
                  [items]="user.items"
                  (clickFilters)="selectFilter($event)"></component-sub-filters>
              </div>
            </div>
          </ng-container>

          <!-- Tab Facets (Status/Stock/Flag/etc.)-->
          <div class="leftNavigation__filtersOption" *ngFor="let config of tabFacets">
            <label class="leftNavigation__filtersOption__label" (click)="showFilter(config)">
              <span class="leftNavigation__filtersOption__labelText">{{ config.name }}</span>
              <span
                class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': config.visible }"></span>
            </label>
            <component-sub-filters
              class="leftNavigation__filtersSubList"
              [ngClass]="{ 'leftNavigation__filtersSubList--active': config.visible }"
              [items]="config.items"
              (clickFilters)="selectFilter($event)"></component-sub-filters>
          </div>

          <!-- Categories -->
          <ng-container>
            <div class="leftNavigation__filtersOption" *ngIf="categories && categories.length > 0">
              <label class="leftNavigation__filtersOption__label" id="categories" (click)="showFilter(categories)">
                <span class="leftNavigation__filtersOption__labelText">Categories</span>
                <span
                  class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
                  [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': categories.visible }"></span>
              </label>
              <component-sub-filters
                class="leftNavigation__filtersSubList"
                [ngClass]="{ 'leftNavigation__filtersSubList--active': categories.visible }"
                [items]="categories"
                (clickFilters)="selectFilter($event)"></component-sub-filters>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</nav>
