<component-notification [notification]="notification"></component-notification>

<header class="topNavigation" *ngIf="router.url !== '/login' && router.url !== '/select'">
  <div class="topNavigation__logo">
    <a class="topNavigation__logoLink" href="http://www.findmine.com/">
      <span class="topNavigation__logoText"><b>Find</b>mine</span>
    </a>
  </div>
  <select (change)="handleClientChange($event)" class="clients-select">
    <option *ngIf="!clients && selectedClient" value="-1" disabled selected>{{ selectedClient.name }}</option>
    <option *ngIf="!clients" value="-1" disabled [selected]="!clients && !selectedClient">Loading clients...</option>
    <option *ngFor="let client of clients" value="{{ client.uid }}" [selected]="client.uid === selectedClient.uid">
      {{ client.name }}
    </option>
  </select>
  <ul class="topNavigation__tabs">
    <ng-container *ngFor="let menu of menuLinks; let i = index">
      <li class="topNavigation__tab">
        <a
          *ngIf="!ruleViolation && !menu.adminRequired"
          class="topNavigation__tabLink"
          [routerLink]="[menu.url]"
          [routerLinkActive]="['topNavigation__tabLink--active']"
          [routerLinkActiveOptions]="{ exact: false }"
          (click)="menu.name === 'Widgets' && goToWidgets()">
          <span class="topNavigation__tabLink__header" attr.name="{{ menu.name }}-tab">{{ menu.name }}</span>
        </a>
        <a
          *ngIf="!ruleViolation && menu.adminRequired && isAdmin"
          class="topNavigation__tabLink"
          [routerLink]="[menu.url]"
          [routerLinkActive]="['topNavigation__tabLink--active']"
          [routerLinkActiveOptions]="{ exact: false }">
          <span class="topNavigation__tabLink__header" attr.name="{{ menu.name }}-tab">{{ menu.name }}</span>
        </a>
        <a *ngIf="ruleViolation && menu.name === 'Create'" class="topNavigation__tabLink topNavigation__tabLink--active">
          <span class="topNavigation__tabLink__header" attr.name="{{ menu.name }}-tab">{{ menu.name }}</span>
        </a>
        <a *ngIf="ruleViolation && menu.name !== 'Create'" class="topNavigation__tabLink" (click)="preventNavigation()">
          <span class="topNavigation__tabLink__header" attr.name="{{ menu.name }}-tab">{{ menu.name }}</span>
        </a>
      </li>
    </ng-container>
  </ul>
  <ul class="topNavigation__buttons">
    <li class="topNavigation__button topNavigation__button--user">
      <a class="topNavigation__buttonLink" (click)="showModal($event)">
        <img class="topNavigation__buttonLinkImage" name="profile-icon" draggable="false" src="assets/glyphicons-4-user@2x.png" alt="" />
      </a>
      <section class="modal-user" [hidden]="!show">
        <span class="arrow-up"></span>
        <main>
          <section>
            <p class="userModalName">
              {{ user.name }}
            </p>
            <p class="userModalEmail">
              {{ user.email }}
            </p>
          </section>
        </main>
        <footer>
          <button class="userModalLogout__button" (click)="logout($event)">Logout</button>
        </footer>
      </section>
    </li>
  </ul>
</header>
