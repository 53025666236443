<component-top-navigation></component-top-navigation>
<component-modal [mode]="'manage'" [user]="user" [clients]="clients" (itemEdited)="onItemEdit($event)" (getItemLooks)="onGetItemLooks()"> </component-modal>
<component-loader [active]="showLoader"></component-loader>
<component-notification [notification]="notification"></component-notification>

<div class="screenContainer">
  <component-filters
    [categories]="categories"
    [filters]="filters"
    [lookUsers]="lookUsers"
    [mode]="'manage'"
    [selectedClient]="selectedClient"
    [selections]="selections"
    [tabFacets]="tabFacets"
    (onClickFilters)="onClickFilters($event)"
    (onClickSearch)="onLookSearch($event)"
    (onSelectStore)="onSelectClient($event)">
  </component-filters>
  <div class="mainScreen">
    <component-manage-header></component-manage-header>
    <component-look-container [looks]="looks" [disableScroll]="disableScroll" (onLoadMore)="loadMoreLooks()" (notifyUser)="displayNotification($event)">
    </component-look-container>
  </div>
</div>
