import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { ClientService } from './client.service';
import { CountService } from './count.service';
import { FacetService } from './facet.service';
import {
  AppStore,
  Category,
  Facet,
  FormattedItemImage,
  Item,
  ItemCategoryMutation,
  ItemColorMutation,
  ItemDeleteMutation,
  ItemFacetMutation,
  ItemFlagMutation,
  ItemQuery,
  ItemsFacetsQueryInput,
  ItemsQuery,
  ItemStatusMutation,
} from './store';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { combineLatest, Observable, Subject } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ItemMutationResponse, ItemStockMutation } from './store/item.store';

import { environment } from 'environments/environment';

const VS_URL = environment.apiUrl + '/api/v3/visually-similar';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

export interface showVsItems {
  show: boolean;
  uni?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  constructor(
    private apollo: Apollo,
    private appStore: AppStore,
    private clientService: ClientService,
    private countService: CountService,
    private facetService: FacetService,
    private http: HttpClient,
  ) {}

  // Add item BehaviorSubject used to add item to Canvas
  private addItem = new Subject<Item>();
  addItem$ = this.addItem.asObservable();
  addToCanvas(item: Item) {
    this.addItem.next(item);
  }

  // Remove item BehaviorSubject used to remove item from Canvas
  private removeItem = new Subject<string>();
  removeItem$ = this.removeItem.asObservable();
  removeFromCanvas(itemUid: string) {
    this.removeItem.next(itemUid);
  }

  // Add pinned item BehaviorSubject used to pin item to look within Canvas
  private pinItem = new Subject<string | null>();
  pinItem$ = this.pinItem.asObservable();
  pinToLook(itemUid: string | null) {
    this.pinItem.next(itemUid);
  }

  // Replace items in Create tab with Visually Similar API results
  private vsItems = new Subject<showVsItems>();
  vsItems$ = this.vsItems.asObservable();
  publishVsItems(show: boolean, uni?: string) {
    this.vsItems.next({ show: show, uni: uni });
  }

  // Store items in application storage
  storeItems(items: Item[]): void {
    items.forEach((item) => {
      item.facets = this.facetService.formatFacets(item.facets);
      item.formattedImage = this.formatImage(item.imageUrl);
      item.shortUid = this.shortenUid(item.uid);
    });
    this.appStore.setState('Items', items);
  }

  // Shorten item UID to 8-digit version
  shortenUid(itemUid: string): string {
    return itemUid.slice(0, 8);
  }

  formatImage(imageUrl?: string): FormattedItemImage {
    const itemImage = {
      edit: undefined,
      label: 'main',
      main: imageUrl?.replace(/\.png/g, '.md$&') ?? '',
      small: imageUrl?.replace(/\.png/g, '.sm$&') ?? '',
      thumbnails: [],
      uid: '',
    } as FormattedItemImage;
    return itemImage;
  }

  // All Items require additional formatting once retrieved from RunwayQL
  formatItem(item: Item): Item {
    if (item.colors) {
      // Sort colors by descending weight
      item.colors = item.colors.sort((a, b) => b.weight - a.weight);

      // Calculate color weight percentage for each live color
      const summedColorWeights: number = item.colors.filter((color) => color.state).reduce((sum, color) => sum + color.weight, 0);
      for (const color of item.colors.filter((color) => color.state)) {
        color.liveWeightPercentage = Math.round((color.weight / summedColorWeights) * 100);
      }
    }
    item.facets = this.facetService.formatFacets(item.facets);
    item.formattedImage = this.formatImage(item.imageUrl);
    item.shortUid = this.shortenUid(item.uid);
    // Set sale value directly on item
    const saleFacet = item.facets.find((facet) => facet.name === 'sale');
    // Not all clients use sale facet, so check for existence
    if (saleFacet) item.sale = saleFacet.items[0].name === 'true'; // Boolstring to boolean
    return item;
  }

  // Used for removal; remove specified look from application storage
  removeItemFromAppStore(itemUid: string): void {
    let allItems = this.appStore.getState('Items');
    if (allItems) {
      allItems = allItems.filter((item) => item.uid !== itemUid);
      this.appStore.setState('Items', allItems);
    }
  }

  // Get single item
  getItem(itemUid: string): Observable<Item> {
    return this.apollo
      .watchQuery<any>({
        query: ItemQuery,
        variables: {
          itemUid: itemUid,
        },
      })
      .valueChanges.pipe(
        map((response) => {
          if (response.data.item) {
            response.data.item = this.formatItem(response.data.item);
          }
          return response.data.item;
        }),
      );
  }

  // Get client items
  getClientItems(clientUid?: string): Observable<Item[]> {
    if (!clientUid) {
      this.clientService.getSelectedClient().subscribe((response) => {
        // eslint-disable-next-line no-param-reassign
        clientUid = response.uid;
      });
    }
    const itemState = this.appStore.getState('Items');
    if (itemState) {
      return this.appStore.subscribe('Items');
    } else {
      return this.apollo
        .watchQuery<any>({
          query: ItemsQuery,
          variables: {
            clientUid: clientUid,
            page: 1,
          },
        })
        .valueChanges.pipe(
          map((response) => {
            this.countService.setItemCount(response.data.items.count);
            // Format item facets and shorten UID
            response.data.items.items.forEach((item, index) => {
              this[index] = this.formatItem(item);
            }, response.data.items.items); // Bind response.data.items.items to 'this' for formatting
            return response.data.items.items;
          }),
          tap((response) => this.appStore.setState('Items', response)),
          tap(() => this.appStore.subscribe('Items')),
        );
    }
  }

  // Make Visually Similar request, then get full items for each returned VS item
  getVisuallySimilarItems(uni: string, appId: string): Observable<Item[]> {
    const pid = uni.split('||')[0];
    const color = uni.split('||')[1];

    const path = `?application=` + appId + `&product_id=` + pid + `&product_color_id=` + color;

    return this.http.get(`${VS_URL}${path}`, httpOptions).pipe(
      concatMap((vsResponse) => {
        if (vsResponse['result'] === 'success') {
          const getFullItems = vsResponse['similar_items'].map((item) => this.getItem(item['item_id']));
          return combineLatest(getFullItems).pipe(
            map((fullItems) => {
              // Add rank to each item
              fullItems.forEach((item: Item) => {
                vsResponse['similar_items'].forEach((vsItem) => {
                  if (item.uid === vsItem['item_id']) {
                    item['rank'] = vsItem['rank'];
                  }
                });
              });
              // Sort items by rank
              fullItems.sort((a, b) => {
                return parseFloat(a['rank']) - parseFloat(b['rank']);
              });
              return fullItems;
            }),
          );
        } else {
          return Observable.of([]);
        }
      }),
      catchError((err) => {
        throw new Error(err);
      }),
    );
  }

  // Edit item category
  editItemCategory(itemUid: string, categoryUid: string): Observable<ItemMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: ItemCategoryMutation,
        variables: {
          itemUid: itemUid,
          itemCategory: {
            categoryUid: categoryUid,
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateItem.item = this.formatItem(response.data.updateItem.item);
          return response.data.updateItem;
        }),
      );
  }

  // Edit item colors
  editItemColor(itemUid: string, colorData: { uid: string; state: boolean }): Observable<ItemMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: ItemColorMutation,
        variables: {
          itemUid: itemUid,
          itemColor: {
            colors: [
              {
                colorUid: colorData.uid,
                colorState: colorData.state,
              },
            ],
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateItem.item = this.formatItem(response.data.updateItem.item);
          return response.data.updateItem;
        }),
      );
  }

  // Edit item facets
  editItemFacet(itemUid: string, facetData: { name: string; value: string }): Observable<ItemMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: ItemFacetMutation,
        variables: {
          itemUid: itemUid,
          itemFacet: {
            facets: [
              {
                facetKey: facetData.name,
                facetValue: facetData.value,
              },
            ],
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateItem.item = this.formatItem(response.data.updateItem.item);
          return response.data.updateItem;
        }),
      );
  }

  // Edit item flag
  editItemFlag(itemUid: string, flagData: boolean): Observable<ItemMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: ItemFlagMutation,
        variables: {
          itemUid: itemUid,
          itemFlag: {
            flagged: flagData,
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateItem.item = this.formatItem(response.data.updateItem.item);
          return response.data.updateItem;
        }),
      );
  }

  // Edit item status
  editItemStatus(itemUid: string, statusData: number): Observable<ItemMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: ItemStatusMutation,
        variables: {
          itemUid: itemUid,
          itemStatus: {
            status: statusData,
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateItem.item = this.formatItem(response.data.updateItem.item);
          return response.data.updateItem;
        }),
      );
  }

  // Edit item stock
  // NOTE: Users can currently only set an In Stock item to OOS and not vice-versa
  // OOS values are hardcoded and need replaced if this ever changes
  editItemStock(itemUid: string): Observable<ItemMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: ItemStockMutation,
        variables: {
          itemUid: itemUid,
          itemStock: {
            stock: [
              {
                stockKey: 'quantity',
                stockValue: '0',
              },
            ],
          },
        },
      })
      .pipe(
        map((response) => {
          response.data.updateItem.item = this.formatItem(response.data.updateItem.item);
          return response.data.updateItem;
        }),
      );
  }

  // Delete item by setting live to false
  deleteItem(itemUid: string): Observable<ItemMutationResponse> {
    return this.apollo
      .mutate<any>({
        mutation: ItemDeleteMutation,
        variables: {
          itemUid: itemUid,
          itemLive: {
            live: false,
          },
        },
      })
      .pipe(
        map((response) => {
          return response.data.updateItem;
        }),
      );
  }

  // Filter items
  filterItems(selections: { categories: Category[]; facets: Facet[] }, page?: number, search?: string): Observable<Item[]> {
    let clientUid: string;
    this.clientService.getSelectedClient().subscribe((response) => {
      clientUid = response.uid;
    });

    const filters: {
      category: string[];
      facets: ItemsFacetsQueryInput[];
      processed: string[];
      status: string[];
    } = {
      category: [],
      facets: [],
      processed: [],
      status: [],
    };

    // Wrap UNI search text in quotes automatically
    if (search.includes('||')) {
      // eslint-disable-next-line no-param-reassign
      search = '"' + search + '"';
    }

    // Add all selected facets to filter object
    const facets = Object.values(selections.facets);
    facets.forEach((facet) => {
      if (facet.type === 'status' || facet.type === 'processed') {
        filters[`${facet.type}`].push(facet.name);
      } else {
        // Item Facets
        const facetInFilters = filters.facets.find((queryFacet, index) => {
          if (queryFacet.facetKey === facet.type) {
            filters.facets[index] = {
              facetKey: facet.type,
              facetValues: [...queryFacet.facetValues, facet.name],
            };
            return true; // Stop searching once identified and updated
          }
        });

        // If facet not already in filters, add it
        if (!facetInFilters) {
          filters.facets.push({
            facetKey: facet.type,
            facetValues: [facet.name],
          });
        }
      }
    });

    // Add all selected categories to filter object
    const categories = Object.values(selections.categories);
    categories.forEach((category) => {
      filters.category.push(category.uid);
    });

    const qlQuery = this.apollo.watchQuery<any>({
      query: ItemsQuery,
      variables: {
        category: filters.category,
        clientUid: clientUid,
        page: page,
        processed: filters.processed,
        status: filters.status,
        textSearch: search,
        facets: filters.facets,
      },
    });

    let itemState = this.appStore.getState('Items');
    if (page > 1 && itemState) {
      return qlQuery.valueChanges.pipe(
        map((response) => {
          if (response.data.items == null && page > 1) {
            return itemState;
          } else if (response.data.items == null) {
            this.countService.setItemCount('0');
            return [];
          } else {
            this.countService.setItemCount(response.data.items.count);
            // Format item facets
            response.data.items.items.forEach((item, index) => {
              this[index] = this.formatItem(item);
            }, response.data.items.items); // Bind response.data.items.items to 'this' for formatting
            itemState = itemState.concat(response.data.items.items);
            return itemState;
          }
        }),
        tap((response) => this.appStore.setState('Items', response)),
        tap(() => this.appStore.subscribe('Items')),
      );
    } else {
      return qlQuery.valueChanges.pipe(
        map((response) => {
          this.countService.setItemCount(response.data.items.count);
          // Format item facets
          response.data.items.items.forEach((item, index) => {
            this[index] = this.formatItem(item);
          }, response.data.items.items); // Bind response.data.items.items to 'this' for formatting
          return response.data.items.items;
        }),
        tap((response) => this.appStore.setState('Items', response)),
        tap(() => this.appStore.subscribe('Items')),
      );
    }
  }
}
