import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CanvasService, CountService, SelectionsService } from '@app/core';
import { Category, Client, Facet } from '@app/core/store';

@Component({
  selector: 'component-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.sass'],
})
export class FiltersComponent {
  // Inputs
  @Input() tabFacets: Facet[] = [];
  @Input() mode: string;
  @Input() searchText = '';
  @Input() selectedClient: Client;

  // Filter Inputs
  @Input() categories: Category[];
  @Input() filters: Facet[];
  @Input() lookUsers: Facet[];
  // Currently selected filters (categories and facets)
  @Input() selections: { authors: Facet[]; categories: Category[]; editors: Facet[]; facets: Facet[] };

  // Outputs
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickFilters = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClickSearch = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelectClient = new EventEmitter();
  @Output() closeCanvas = new EventEmitter();

  // Public Properties
  itemCount = '';
  lookCount = '';
  filterText = '';

  constructor(
    private canvasService: CanvasService,
    private countService: CountService,
    private route: ActivatedRoute,
    private router: Router,
    private selectionsService: SelectionsService,
  ) {
    this.selectionsService.clearSelections$.subscribe(() => {
      this.clearSelections();
    });

    this.countService.itemCount$.subscribe((count) => {
      this.itemCount = count;
    });
    this.countService.lookCount$.subscribe((count) => {
      this.lookCount = count;
    });
  }

  showFilter(filter: Category | Facet): void {
    filter.visible = !filter.visible;
  }

  clearSelections(): void {
    this.tabFacets.forEach((facet) => {
      facet.items?.forEach((item) => this.clearSelectedFilters(item));
    });

    if (this.lookUsers) {
      this.lookUsers.forEach((user) => this.clearSelectedFilters(user));
    }

    this.filters.forEach((filter) => this.clearSelectedFilters(filter));
    this.categories.forEach((category) => this.clearSelectedFilters(category));
    this.selections = { authors: [], categories: [], editors: [], facets: [] };
    this.searchText = '';
    this.selectionsService.storeSelections(this.mode, { selections: this.selections });
    this.fireEventOnFilterSelection();
  }

  clearSelectedFilters(filter: Category | Facet): void {
    filter.selected = false;
    filter.visible = false;
    if (filter.items && filter.items.length) {
      filter.items.forEach((item) => this.clearSelectedFilters(item));
    }
  }

  selectFilter(filter: Category | Facet): void {
    this.propagateChildrenClick(filter, !filter.selected, true);
    this.fireEventOnFilterSelection();
  }

  propagateChildrenClick(filter: Category | Facet, state: boolean, edit: boolean) {
    filter.selected = state;
    if (edit) {
      // Add selected filter (category or facet or author or editor) to selections object
      if (this.filterIsCategory(filter)) {
        if (this.selections.categories[filter.uid] && filter.selected === false) {
          delete this.selections.categories[filter.uid];
        } else if (filter.selected === true) {
          this.selections.categories[filter.uid] = filter;
        }
      } else if (filter.type == 'authors' || filter.type == 'editors') {
        if (this.selections[filter.type][filter.value] && filter.selected === false) {
          delete this.selections[filter.type][filter.value];
        } else if (filter.selected === true) {
          this.selections[filter.type][filter.value] = filter;
        }
      } else {
        if (this.selections.facets[filter.type + '_' + filter.name] && filter.selected === false) {
          delete this.selections.facets[filter.type + '_' + filter.name];
        } else if (filter.selected === true) {
          this.selections.facets[filter.type + '_' + filter.name] = filter;
        }
      }

      if (filter.items && filter.items.length) {
        filter.items.forEach((item) => {
          this.propagateChildrenClick(item, state, edit);
        });
      }
    }
  }

  private filterIsCategory(filter: Category | Facet): filter is Category {
    return filter.type === 'category';
  }

  search(): void {
    this.onClickSearch.next({
      selections: this.selections,
      query: this.searchText,
    });
  }

  // Private Methods
  private fireEventOnFilterSelection(): void {
    this.onClickFilters.next({
      selections: this.selections,
      query: this.searchText,
    });
  }
}
